html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background: url("271553111092913f3f41951bc04a5f46.jpg") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.asciinema-terminal {
  font-family: "FuraCode Nerd Font";
}
.asciinema-player {
  border-radius: 6px;
  box-shadow: 0px 6px 32px rgba(0,4,16,0.64);
}
.control-bar {
  bottom: -32px !important;
  transition: 0.2s bottom ease-in;
}
.asciinema-player:hover .control-bar {
  bottom: 0 !important;
}
